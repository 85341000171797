<template>
  <plan-card>
    <div v-if="isSplitFunding" class="split-scale">
      <div>
        <h3>Allocation Percentage Breakdown</h3>
      </div>

      <div class="allocation-row">
        <div class="allocation-row-label">
          <span>{{ selected.client.allocation.weight | percent }}</span>
          <span>Company Allocation</span>
        </div>
        <div>
          <div class="allocation-bar" ref="company"></div>
        </div>
      </div>

      <div v-for="unit in orgUnits" :key="unit.id" class="allocation-row">
        <div class="allocation-row-label">
          <span>{{ unit.allocation.weight | percent }}</span>
          <span>{{ unit.label }} Allocation</span>
        </div>
        <div>
          <div class="allocation-bar" :ref="`unit_${unit.id}`"></div>
        </div>
      </div>

      <div v-if="employee.performance.mode === 'as-component'" class="allocation-row">
        <div class="allocation-row-label">
          <span>{{ employee.allocation.weight | percent }}</span>
          <span>Individual Allocation</span>
        </div>
        <div>
          <div class="allocation-bar" ref="employee"></div>
        </div>
      </div>

      <div class="allocation-row">
        <div class="allocation-row-label">
          <span>100%</span>
          <span>Total Allocation</span>
        </div>
        <div>
          <div class="allocation-total"></div>
        </div>
      </div>
    </div>
    <forecast-scale v-else
        :tiers="selected.tiers" :forecast="selected.forecast" :client="selected.client"
        :distributions="selected.distributions" :isEos="selected.plan.isEos"
    />
  </plan-card>
</template>

<script>
import PlanCard from "@/components/plans/global/PlanCard.vue";
import ForecastScale from "@/components/plans/short-term/scale/ForecastScale.vue";

export default {
  name: "PlanScale",

  mixins: [],

  components: {
    PlanCard,
    ForecastScale,
  },

  props: {
    selected: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    isSplitFunding() {
      return this.selected?.plan?.funding_mode === 'split';
    },

    orgUnits() {
      return Object.values(this.selected.organization.units);
    },

    employee() {
      return this.selected.employee;
    },
  },

  methods: {},

  mounted() {},

  created() {},
}
</script>

<style lang="scss" scoped>
.company-details {
  border-top: thin solid $color-card-border;
}

.split-scale {
  width:100%;
  padding: 1rem 2rem;
}

.allocation-row {
  width:100%;

  display:grid;
  grid-template-columns:1fr 5fr;
  column-gap:0.5em;

  margin-bottom:0.5em;
}

.allocation-row-label {
  display:flex;
  flex-direction:column;
  grid-column:span 1;
  text-align:left;
}

.allocation-bar {
  background-color: var(--brand-color);

  height: 100%;

  border-top-right-radius: 0.1875em;
  border-bottom-right-radius: 0.1875em;
}

.allocation-total {
  background-color: var(--highlight-color);

  width: 100%;
  height: 100%;

  border-top-right-radius: 0.1875em;
  border-bottom-right-radius: 0.1875em;
}
</style>